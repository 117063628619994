import Prism from "prismjs";
import { Autoplay, Navigation, Pagination } from "swiper";
import Swiper from "swiper/bundle";
import 'ace-builds';
import $ from 'jquery';
import 'ace-builds/webpack-resolver';

// スマホからのアクセスがあった時にアラート表示
// function isSmartphone() {
//     const userAgent = navigator.userAgent || navigator.vendor || window.opera;
//     return /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
// }
// if (isSmartphone()) {
//     const messageElement = document.getElementById("alert-message");
//     //messageElement.innerHTML = "スマートフォンからのアクセスが確認されました。";
//     messageElement.style.display = "block";
//     alert('PCからアクセスするようにお願い致します。スマホでも閲覧可能ですが、推奨環境はPCでのChrome・safari・Edgeの最新バージョンになります。')
// }

// TOPのスワイパー
const swiper = new Swiper(".swiper", {
    // ページネーションが必要なら追加
    pagination: {
        el: ".swiper-pagination",
    },
    // ナビボタンが必要なら追加
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
});

// ace エディター
let editorElement = document.getElementById('editor');
// If we have an editor element
if (editorElement) {
    // pass options to ace.edit
    let editor = ace.edit(document.getElementById('editor'), {
        mode: "ace/mode/html",
        theme: "ace/theme/dracula",
        fontSize: 14,
        height: "400px",
        scrollPastEnd: true,
    })
    // use setOptions method to set several options at once
    editor.setOptions({
        autoScrollEditorIntoView: true,
        copyWithEmptySelection: true,
    });
};


// フラッシュメッセージの非表示処理
$(document).ready(function() {
    $('#flash-message').on('animationend', function() {
        $(this).remove();
    });
});

// ヘッダーのクリックイベントでドロップダウンメニューを表示
$(document).ready(function () {
    $("#dropdown_menu").click(function (event) {
        event.stopPropagation(); // Propagation of the event to the document is stopped
        $(".dropdown-content").toggle();
    });

    $(document).click(function() {
        $(".dropdown-content").hide();
    });
});
